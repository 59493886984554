import React from 'react';
import getImageByKey from '../utils/getImages';
import GoToTop from '../utils/goToTop';
import { useMobile } from '../utils/hooks';
import { tabTitle } from '../utils/tabTitle';

export default function RequestTechAssist() {
  const { isMobile } = useMobile();
  tabTitle('Tech Assistance Request - Find Recovery Housing Now');

  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-4 p-0 card border-0 d-none d-md-block">
          <img src={getImageByKey('heroColor')} className="w-100" alt="Find Recovery Housing" />
          <span className="card-img-overlay d-flex align-items-center justify-content-center">
            <img
              src={getImageByKey('logoWhite')}
              className="img-fluid"
              alt="Find Recovery Housing"
            />
          </span>
        </div>
        <div className={`col-12 col-md-8 p-0 ${isMobile ? 'vh-100' : ''}`}>
          <iframe
            title="Request for technical assistance"
            width="100%"
            height="100%"
            src="https://app.smartsheet.com/b/form/1c3042bb5e984fd6ac3898346fd77ef1"
          ></iframe>
        </div>
      </div>
      <GoToTop />
    </>
  );
}
